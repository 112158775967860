import type { providers } from 'ethers';
import type { CryptoCossacks2022 as CCBaseContract } from '@crypto-cossacks/token/typechain';

export const isFounder = async (
  contract: CCBaseContract
): Promise<boolean> => {

  const signer = (contract.provider as providers.Web3Provider).getSigner();
  const address = await signer.getAddress();

  return contract.isFounder(address);
};
