import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { useAppState } from '../../store';
import { ConnectButton } from './index';
import { colors, fonts, responsive } from '../../styles';

export const SignOutButtonWrapper = styled.button`
  font-size: ${fonts.size.h5};
  color: rgb(${colors.white});
  border: none;
  background-color: rgb(${colors.blueblack});
  margin-left: 12px;

  @media (max-width: ${responsive.md}) {
    margin-left: 0;
  }
`;

export const LabelDesc = styled.span`
  @media (max-width: ${responsive.md}) {
    display: none;
  }
`;

export const LabelMob = styled.span`
  display: none;
  @media (max-width: ${responsive.md}) {
    display: inline-block;
  }
`;

export const SignInButton = () => {
  const { isConnecting, account, signIn } = useAppState();

  if (isConnecting || account) {
    return null;
  }

  return (
    <ConnectButton
      onClick={() => signIn()}
      disabled={isConnecting}
    >
      <LabelDesc>
        Connect wallet
      </LabelDesc>
      <LabelMob>
        Connect
      </LabelMob>
    </ConnectButton>
  )
};

export const SignOutButton = () => {
  const { isConnecting, account, signOut } = useAppState();

  if (!account && !isConnecting) {
    return null;
  }

  return (
    <SignOutButtonWrapper
      onClick={() => signOut()}
      disabled={isConnecting}
    >
      {
        isConnecting
          ? <FontAwesomeIcon icon='sync-alt' spin />
          : <FontAwesomeIcon icon='sign-out-alt' />
      }
    </SignOutButtonWrapper>
  )
};
