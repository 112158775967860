import Logger from './utils/logger';

// Initialize logger
const logger = Logger('Config');

export interface NetworkConfig {
  name: string;
  chainId: number;
  blockExplorer: string;
}

export interface DappConfig {
  infuraId: string;
  defaultNetworkId: number;
  tokenAddress: string;
  networks: NetworkConfig[];
  imgBase: string;
}

if (!process.env.REACT_APP_NETWORK_ID || process.env.REACT_APP_NETWORK_ID === '') {
  logger.error('REACT_APP_NETWORK_ID must be provided in the ENV');
  throw new Error('REACT_APP_NETWORK_ID must be provided in the ENV');
}

if (!process.env.REACT_APP_INFURA_ID || process.env.REACT_APP_INFURA_ID === '') {
  logger.error('REACT_APP_INFURA_ID must be provided in the ENV');
  throw new Error('REACT_APP_INFURA_ID must be provided in the ENV');
}

if (!process.env.REACT_APP_TOKEN_ADDRESS) {
  logger.error('REACT_APP_TOKEN_ADDRESS must be provided in the ENV');
  throw new Error('REACT_APP_TOKEN_ADDRESS must be provided in the ENV');
}

if (!process.env.REACT_APP_IMG_BASE) {
  logger.error('REACT_APP_IMG_BASE must be provided in the ENV');
  throw new Error('REACT_APP_IMG_BASE must be provided in the ENV');
}

const config: DappConfig = {
  infuraId: process.env.REACT_APP_INFURA_ID,
  defaultNetworkId: Number(process.env.REACT_APP_NETWORK_ID),
  tokenAddress: process.env.REACT_APP_TOKEN_ADDRESS,
  imgBase: process.env.REACT_APP_IMG_BASE,
  networks: [
    // {
    //   name: 'rinkeby',
    //   chainId: 4,
    //   blockExplorer: 'https://rinkeby.etherscan.io'
    // },
    {
      name: 'main',
      chainId: 1,
      blockExplorer: 'https://etherscan.io'
    }
  ]
};

export const getInfuraId = (): string => {
  return config.infuraId;
}

export const getNetworks = () => config.networks;

export const getNetworksIds = () => config.networks.map(n => n.chainId);

export const getNetworksNames = () => config.networks.map(n => n.name);

export const getDefaultNetwork = (): NetworkConfig => {
  const network = config.networks
    .filter(n => n.chainId === config.defaultNetworkId)[0];
  if (!network) {
    logger.error('Network not found in the configuration');
    throw new Error('Network not found in the configuration');
  }
  return network;
}

export const getTokenAddress = () => config.tokenAddress;

export const getImgBase = () => config.imgBase;

export default config;
