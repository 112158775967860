import type { CCContract } from '../contractLib';
import { useState, useEffect } from 'react';

export type UseIsPausedHook = [
  isPaused: boolean,
  error: string | undefined
];

export const useIsPaused = (token: CCContract): UseIsPausedHook => {
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    const resetState = () => {
      setError(undefined);
      setIsPaused(false);
    };

    const checkIsPaused = async () => {
      try {
        if (token) {
          const paused = await token.isPaused();
          setIsPaused(paused);
        } else {
          resetState();
        }
      } catch (error) {
        setError(error.message || 'Unknown error');
        resetState();
      }
    };

    checkIsPaused();
  }, [token]);

  return [isPaused, error];
};
