import React, { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { colors, fonts, responsive } from '../../styles';

export interface PortalProps {
  children: React.ReactNode;
  onClose: Function;
}

export interface ModalProps {
  title?: string;
  children: React.ReactNode;
  show: boolean;
  onClose: Function;
}

const Portal = ({
  children,
  onClose = () => {}
}: PortalProps) => {
  const closeOnEscapeKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape' || e.keyCode === 27) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, [closeOnEscapeKeyDown]);

  return createPortal(children, document.getElementById('root'));
};

const ModalWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: rgb(${colors.dark});
  z-index: 99999;
`;

const ModalContent = styled.div`
  width: 500px;
  background-color: rgb(${colors.blueblack});

  @media (max-width: ${responsive.md}) {
    width: 99%;
  }
`;

const ModalHeader = styled.div`
  padding: 10px;
`;

const ModalTitle = styled.div`
  margin: 0;
`;

const ModalBody = styled.div`
  padding: 10px;
  border: 1px solid rgb(${colors.lightgrey});
  color: rgb(${colors.white});
`;

const ModalFooter = styled.div`
  padding: 10px;
`;

const CloseBtn = styled.button`
  padding: 12px 18px;
  /* border: 1px solid rgb(${colors.red}); */
  background-color: rgb(${colors.white});
  color: rgb(${colors.blueblack});
  cursor: pointer;
  font-family: ${fonts.family.Ukraine};
  font-size: ${fonts.size.small};
  font-weight: ${fonts.weight.medium};
  border-radius: 6px;
`;

export const Modal = ({
  title,
  children,
  show = false,
  onClose = () => {}
}: ModalProps) => {

  if (!show) {
    return null;
  }

  return (
    <Portal onClose={onClose}>
      <ModalWrapper onClick={() => onClose()}>
        <ModalContent onClick={e => e.stopPropagation()}>
          {title &&
            <ModalHeader>
              <ModalTitle>{title}</ModalTitle>
            </ModalHeader>
          }
          <ModalBody>
            {children}
          </ModalBody>
          <ModalFooter>
            <CloseBtn onClick={() => onClose()}>
              Close
            </CloseBtn>
          </ModalFooter>
        </ModalContent>
      </ModalWrapper>
    </Portal>
  );
};
