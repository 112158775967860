import type { CCContract } from '../../contractLib';
import type { TokenData } from '../../contractLib/types';
import styled from 'styled-components';
import { useState, useCallback, useEffect } from 'react';
import { usePoller } from '../../hooks/usePoller';
import { useError } from '../../hooks/useError';
import { Container } from '../Layout/Container';
import { TokenCard } from '../Layout/TokenCard';
import { BlockTitle } from '../Typography/BlockTitle';
import { RefreshButton } from '../buttons';
import { Modal } from '../Modal';
import { responsive } from '../../styles';
import Logger from '../../utils/logger';
import { useAppDispatch } from '../../store';
import { colors } from '../../styles';

// Initialize logger
const logger = Logger('MyTokens');

export interface MyTokensProps {
  token: CCContract,
  account?: string,
  pollInterval?: number
}

export interface TokenImageProps {
  src: string;
  width?: string;
  height?: string;
}

export interface TokensData {
  [account: string]: TokenData[]
}

export const TokenCardsWrapper = styled.div`
  display: block;
`;

export const RefreshBtnWrapper = styled.div`
  display: inline-block;
  margin-bottom: 20px;
  vertical-align: middle;
  cursor: pointer;

  /* @media (max-width: ${responsive.md}) {
    width: 100%;
    margin: 0;
    text-align: center;
  } */
`;

const RefreshBtnInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 145px;
  height: 167px;
  background-color: rgb(${colors.grey});
  border-radius: 8px;
`;

export const TokenClickArea = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0;
`;

export const TokenImage = styled.div`
  width: 480px;
  height: 480px;
  background-image: url(${({ src }: TokenImageProps) => src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: ${responsive.md}) {
    width: 100%;
    height: 400px;
  }
`;

export const MyTokens = (
  {
    token,
    account,
    pollInterval = 10000
  }: MyTokensProps
) => {
  const setError = useError();
  const dispatch = useAppDispatch();
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [ownedTokens, setOwnedTokens] = useState<TokensData>({});
  const [details, setDetails] = useState<TokenData | undefined>();

  const getOwnedTokens = useCallback(
    async () => {
      try {
        setRefreshing(true);
        const newOwnedTokens = await token.getOwnedTokens(account);
        logger.debug('Owned tokens', account, newOwnedTokens);
        setOwnedTokens(ownedTokensState => ({
          ...ownedTokensState,
          [account]: newOwnedTokens
        }));
        dispatch({
          type: 'SET_IS_OWN_TOKENS',
          payload: newOwnedTokens && newOwnedTokens.length > 0
        });
        setRefreshing(false);
      } catch (err) {
        setRefreshing(false);
        setError(err.message || 'Unknown error');
      }
    },
    [token, account, setError, dispatch]
  );

  useEffect(
    () => {
      setOwnedTokens({});
    },
    [token, account]
  );

  usePoller(
    getOwnedTokens,
    account !== undefined,
    pollInterval,
    `getOwnedTokens for account: ${account}`
  );

  if (!token || ownedTokens[account] === undefined || ownedTokens[account].length === 0) {
    return null;
  }

  return (
    <Container>
      <BlockTitle>
        My own tokens
      </BlockTitle>
      <TokenCardsWrapper>
        {ownedTokens[account].map(
          (t: TokenData, i: number) => (
            <TokenCard
              key={i}
              token={t}
              onClick={() => setDetails(t)}
            />
          )
        )}
        <RefreshBtnWrapper>
          <RefreshBtnInner onClick={getOwnedTokens}>
            <RefreshButton
              refresh={getOwnedTokens}
              refreshing={refreshing}
            />
          </RefreshBtnInner>
        </RefreshBtnWrapper>
      </TokenCardsWrapper>
      <Modal
        show={details !== undefined}
        onClose={() => setDetails(undefined)}
      >
        {details !== undefined &&
          <>
            <div>
              #{details.id} {details.name}
            </div>
            <TokenImage src={details.image} />
          </>
        }
      </Modal>
    </Container>
  );
};
