import styled from 'styled-components';
import { colors, fonts, responsive } from '../styles';
import { centerEllipsis } from '../utils/strings';
import { getTokenAddress } from '../config';

export const LinkWrapper = styled.div`
  font-size: ${fonts.size.medium};
  font-weight: ${fonts.weight.normal};
  color: rgb(${colors.lightgrey});
  margin: 40px 0 40px 0;

  @media (max-width: ${responsive.md}) {
    text-align: center;
  }
`;

export const Link = styled.a`
  text-decoration: underline;
  color: rgb(${colors.white});
`;

export const Label = styled.span`
  white-space: nowrap;
`;

export const Br = styled.br`
  display: none;
  @media (max-width: ${responsive.md}) {
    display: inline-block;
  }
`;

export const ContractLink = () => {

  return (
    <LinkWrapper>
      <Label>
        <strong>Verified smart contract:</strong>&nbsp;
      </Label>
      <Br />
      <Link
        href={`https://etherscan.io/address/${getTokenAddress()}#code`}
        target='_blank'
        rel='noopener noreferrer'
      >
        {centerEllipsis(getTokenAddress())}
      </Link>
    </LinkWrapper>
  );
};
