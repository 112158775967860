import type { BigNumber } from 'ethers';
import type { CryptoCossacks2022 as CCBaseContract } from '@crypto-cossacks/token/typechain';
import { BigNumber as BN } from 'ethers';

// Check is token mintable
export const isTokenMintable = (
  contract: CCBaseContract,
  tokenId: number | string | BigNumber
): Promise<boolean> => {

  if (!BN.isBigNumber(tokenId)) {
    tokenId = BN.from(tokenId);
  }

  return contract.isTokenMintable(tokenId);
};
