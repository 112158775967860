import type { TokenData } from '../../contractLib/types';
import styled from 'styled-components';
import { colors, fonts, responsive } from '../../styles';

export interface TokenCardProps {
  token: TokenData;
  onClick?(): void;
}

export interface TokenImageProps {
  src: string;
  width?: string;
  height?: string;
}

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 20px 0;
`;

export const ImageWrapperInner = styled.div`
  border: 1px solid rgb(145, 145, 145);
  border-radius: 8px;
  overflow: hidden;
`;

export const TokenCardWrapper = styled.div`
  display: inline-block;
  padding: 4px;
  background-color: rgb(${colors.white});
  margin-right: 17px;
  margin-bottom: 20px;
  vertical-align: middle;
  border-radius: 8px;

  ${({ onClick }) =>
    onClick !== undefined
      ? 'cursor: pointer;'
      : ''
  }

  /* &:nth-child(6n) {
    margin-right: 0;
  } */

  @media (max-width: ${responsive.md}) {
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
`;

export const TokenId = styled.div`
  font-size: ${fonts.size.tiny};
  font-weight: ${fonts.weight.normal};
  color: rgb(${colors.black});
  margin-bottom: 4px;
`;

export const TokenImage = styled.div`
  width: ${({ width }) => !!width ? width : '139px'};
  height: ${({ height }) => !!height ? height : '139px'};
  position: relative;

  &:before {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(${({ src }: TokenImageProps) => src});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &:after {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(${colors.lightgrey});
    font-size: ${fonts.size.tiny};
    content: "Loading from IPFS...";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @media (max-width: ${responsive.md}) {
    width: ${({ width }) => !!width ? width : 'calc(50vw - 40px)'};
    height: ${({ height }) => !!height ? height : 'calc(50vw - 40px)'};
  }
`;

export const TokenCard = ({ token, ...props }: TokenCardProps) => (
  <TokenCardWrapper {...props}>
    <TokenId>#{token.id}</TokenId>
    <TokenImage src={token.image} />
  </TokenCardWrapper>
);

export const TokenMintCard = ({ token }: TokenCardProps) => (
  <ImageWrapper>
    <ImageWrapperInner>
      <TokenImage src={token.image} width='250px' height='250px' />
    </ImageWrapperInner>
  </ImageWrapper>
);
