import type { BigNumber } from 'ethers';
import type { CCContract } from '../contractLib';
import { useState, useEffect } from 'react';
import { BigNumber as BN } from 'ethers';

export interface TakenRange {
  from: number;
  to: number;
}

export type UseTokenRangeHook = [
  range: TakenRange,
  price: BigNumber,
  error: string | undefined
];

export const useTokenRange = (token: CCContract): UseTokenRangeHook => {
  const [price, setPrice] = useState<BigNumber | undefined>();
  const [range, setRange] = useState<TakenRange | undefined>();
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    const resetState = () => {
      setError(undefined);
      setPrice(undefined);
      setRange(undefined);
    };

    const checkIsFounder = async () => {
      try {
        if (token) {
          const tokenPrice = await token.getPrice();
          const isFounder = await token.isFounder();
          setPrice(
            !isFounder
              ? tokenPrice
              : BN.from(0)
          );
          setRange(
            !isFounder
              ? {
                from: 0,
                to: 8999
              }
              : {
                from: 9000,
                to: 9999
              }
          );
        } else {
          resetState();
        }
      } catch (error) {
        setError(error.message || 'Unknown error');
        resetState();
      }
    };

    checkIsFounder();
  }, [token]);

  return [range, price, error];
};
