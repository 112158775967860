import type { BigNumber, providers } from 'ethers';
import type { CryptoCossacks2022 as CCBaseContract } from '@crypto-cossacks/token/typechain';
import { BigNumber as BN } from 'ethers';

// Estimate token minting
export const estimateMinting = async (
  contract: CCBaseContract,
  tokenId: number | string | BigNumber
): Promise<BigNumber> => {

  if (!BN.isBigNumber(tokenId)) {
    tokenId = BN.from(tokenId);
  }

  const value = await contract.getPrice();
  const sender = (contract.provider as providers.Web3Provider).getSigner();
  const contractWithSigner = contract.connect(sender);

  return contractWithSigner
    .estimateGas.mint(
      tokenId,
      {
        value,
      }
    );
};
