import type { BigNumber } from 'ethers';
import type { CryptoCossacks2022 as CCBaseContract } from '@crypto-cossacks/token/typechain';
import type { TokenData } from '../types';
import type { NFTMetadata } from '../../schemas/types';
import { BigNumber as BN } from 'ethers';
import { convertIpfsUri } from '../utils/request';
import { getImgBase } from '../../config';
import Logger from '../../utils/logger';

// Initialize logger
const logger = Logger('getTokenMetadataById');

// Returns a token information
export const getTokenMetadataById = async (
  contract: CCBaseContract,
  tokenId: number | string | BigNumber
): Promise<TokenData | null> => {

  if (!BN.isBigNumber(tokenId)) {
    tokenId = BN.from(tokenId);
  }

  let metadata: Partial<NFTMetadata>;

  // const baseUri = await contract.baseURI();
  // const tokenUri = convertIpfsUri(`${baseUri}${tokenId.toString()}`);
  const name = await contract.getNickName(tokenId);

  metadata = {
    image: `ipfs://${getImgBase()}/${tokenId.toString()}.png`,
    name
  }

  // metadata = await request(
  //   tokenUri,
  //   'GET',
  //   undefined,
  //   undefined,
  //   100000 // 100sec timeout
  // ) as NFTMetadata;
  metadata.image = convertIpfsUri(metadata.image);

  logger.debug(`Token #${tokenId.toString()} metadata`, metadata);

  return {
    id: tokenId.toNumber(),
    image: metadata.image,
    name: metadata.name,
    description: metadata.description,
    attributes: metadata.attributes
  };
}
