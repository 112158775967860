import styled from 'styled-components';
import { colors, fonts } from '../styles';

export const ErrorMessageWrapper = styled.div`
  font-size: ${fonts.size.medium};
  font-weight: ${fonts.weight.normal};
  color: rgb(${colors.red});
  margin: 20px 0 20px 0;
  padding: 10px;
  background-color: rgb(${colors.light});
  border-radius: 8px;
`;

export const ErrorMessage = ({ message }) => {

  if (!message || message === '') {
    return null;
  }

  return (
    <ErrorMessageWrapper>
      {message}
    </ErrorMessageWrapper>
  );
}
