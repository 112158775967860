import type { BigNumber, providers } from 'ethers';
import type { CryptoCossacks2022 as CCBaseContract } from '@crypto-cossacks/token/typechain';
import type { MethodOverrides, TxHashCallbackFn } from './utils/sendHelper';
import type { TokenData } from './types';
import { ethers } from 'ethers';
import {
  CryptoCossacks as CCossacksContract
} from '@crypto-cossacks/token';
import * as regexp  from './utils/regexp';

// Token methods:
// getTokens(cursor: number, count: number, includeMinted: boolean): Promise<TokenData[]>

import { getTokenById } from './api/getTokenById';
import { getTokenMetadataById } from './api/getTokenMetadataById';
import { getOwnedTokens } from './api/getOwnedTokens';
import { isTokenMintable } from './api/isTokenMintable';
import { mintToken } from './api/mintToken';
import { isPaused } from './api/isPaused';
import { getPrice } from './api/getPrice';
import { estimateMinting } from './api/estimateMinting';
import { isFounder } from './api/isFounder';
import { getNickName } from './api/getNickName';

export type KnownProvider =
  | providers.ExternalProvider
  | providers.JsonRpcProvider
  | providers.Web3Provider
  | providers.Provider
  | string;

export class CCContract {
  readonly address: string;
  readonly provider: providers.Provider;
  readonly contract: CCBaseContract;

  constructor(
    contractAddress: string,
    providerOrUri: KnownProvider
  ) {

    if (regexp.ethereumAddress.exec(contractAddress)) {
      this.address = contractAddress;
    } else {
      throw new Error(
        `cryptoCossacksContract: Invalid smart contract address: ${contractAddress}`
      );
    }

    if (typeof providerOrUri === 'string' && providerOrUri !== '') {
      this.provider = new ethers.providers.JsonRpcProvider(providerOrUri);
    } else if (typeof providerOrUri === 'object') {

      if ((providerOrUri as providers.ExternalProvider).isMetaMask) {
        // using window.ethereum provided as providerOrUri
        this.provider = new ethers.providers.Web3Provider(
          providerOrUri as providers.ExternalProvider
        );
      } else if (typeof (providerOrUri as providers.JsonRpcProvider).send === 'function') {
        // using raw provider
        this.provider = providerOrUri as providers.JsonRpcProvider;
      }
    }

    if (!this.provider) {
      throw new Error(
        `cryptoCossacksContract: Unable to initialize provider': ${providerOrUri}`
      );
    }

    this.contract = new ethers.Contract(
      this.address,
      CCossacksContract.abi,
      this.provider
    ) as unknown as CCBaseContract;
  }

  // Returns a token information
  getTokenById(
    tokenId: number | string | BigNumber
  ): Promise<TokenData | null> {
    return getTokenById(this.contract, tokenId);
  }

  // Returns a token information (not minted token)
  getTokenMetadataById(
    tokenId: number | string | BigNumber
  ): Promise<TokenData | null> {
    return getTokenMetadataById(this.contract, tokenId);
  }

  // Check is token mintable
  isTokenMintable(
    tokenId: number | string | BigNumber
  ): Promise<boolean> {
    return isTokenMintable(this.contract, tokenId);
  }

  // Return all the tokens that owned by an address
  getOwnedTokens(
    ownerAddress: string
  ): Promise<TokenData[] | []> {
    return getOwnedTokens(this.contract, ownerAddress);
  }

  // Mint token
  mintToken(
    tokenId: number | string,
    overrides: MethodOverrides,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    transactionHashCb: TxHashCallbackFn,
    confirmations: number
  ): Promise<TokenData> {
    return mintToken(this.contract, tokenId, overrides, transactionHashCb, confirmations);
  }

  // Check is contract is paused
  isPaused(): Promise<boolean> {
    return isPaused(this.contract);
  }

  // Get configured token price
  getPrice(): Promise<BigNumber> {
    return getPrice(this.contract);
  }

  // Estimate minting
  estimateMinting(
    tokenId: number | string
  ): Promise<BigNumber> {
    return estimateMinting(this.contract, tokenId);
  }

  // Check is current account is the founder
  isFounder(): Promise<boolean> {
    return isFounder(this.contract);
  }

  getNickName(tokenId: number | string): Promise<string> {
    return getNickName(this.contract, tokenId);
  };
}
