import {
  // IconLookup,
  // IconDefinition,
  // findIconDefinition,
  library
} from '@fortawesome/fontawesome-svg-core';
import {
  faSignInAlt,
  faSignOutAlt,
  faSyncAlt,
  faCheckCircle,
  faBan,
  faSquare,
  faCheckSquare,
  faCircle,
  faPlus,
  faMinus
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faSyncAlt,
  faSignInAlt,
  faSignOutAlt,
  faCheckCircle,
  faBan,
  faSquare,
  faCheckSquare,
  faCircle,
  faPlus,
  faMinus
);

export const colors: { [name: string]: string } = {
  white: '255, 255, 255',
  light: '245, 245, 245',
  black: '0, 0, 0',
  blueblack: '0, 0, 16',
  dark: '74, 74, 74',
  lightgrey: '172, 172, 172',
  grey: '25, 25, 25',
  green: '96, 223, 181',
  purple: '101, 94, 179',
  red: '242, 62, 94',
};

export const fonts = {
  size: {
    tiny: "12px",
    small: "16px",
    medium: "19px",
    large: "34px",
    h1: "60px",
    h2: "50px",
    h3: "40px",
    h4: "32px",
    h5: "24px",
    h6: "20px",
  },
  weight: {
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
  },
  family: {
    Ukraine: 'Ukraine, sans-serif',
    IBM: '"IBM Plex Mono", monospace',
  },
};

export const transitions = {
  short: "all 0.1s ease-in-out",
  base: "all 0.2s ease-in-out",
  long: "all 0.3s ease-in-out",
  button: "all 0.15s ease-in-out",
};

export const shadows = {
  soft: "0 4px 6px 0 rgba(50, 50, 93, 0.11), 0 1px 3px 0 rgba(0, 0, 0, 0.08), inset 0 0 1px 0 rgba(0, 0, 0, 0.06)",
  medium: "0 3px 6px 0 rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(50, 50, 93, 0.02), 0 5px 10px 0 rgba(59, 59, 92, 0.08)",
  big: "0 15px 35px 0 rgba(50, 50, 93, 0.06), 0 5px 15px 0 rgba(50, 50, 93, 0.15)",
  hover: "0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.08), inset 0 0 1px 0 rgba(0, 0, 0, 0.06)",
};

export const responsive = {
  xs: '0',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
};

export const globalStyle = `
  @font-face {
    font-family: Ukraine;
    src: url(fonts/e-Ukraine-Thin.eot);
    src: url(fonts/e-Ukraine-Thin.eot?#iefix) format("embedded-opentype"), url(fonts/e-Ukraine-Thin.woff2) format("woff2"), url(fonts/e-Ukraine-Thin.woff) format("woff"), url(fonts/e-Ukraine-Thin.ttf) format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap
  }

  @font-face {
    font-family: Ukraine;
    src: url(fonts/e-Ukraine-UltraLight.eot);
    src: url(fonts/e-Ukraine-UltraLight.eot?#iefix) format("embedded-opentype"), url(fonts/e-Ukraine-UltraLight.woff2) format("woff2"), url(fonts/e-Ukraine-UltraLight.woff) format("woff"), url(fonts/e-Ukraine-UltraLight.ttf) format("truetype");
    font-weight: 200;
    font-style: normal;
    font-display: swap
  }

  @font-face {
    font-family: Ukraine;
    src: url(fonts/e-Ukraine-Light.eot);
    src: url(fonts/e-Ukraine-Light.eot?#iefix) format("embedded-opentype"), url(fonts/e-Ukraine-Light.woff2) format("woff2"), url(fonts/e-Ukraine-Light.woff) format("woff"), url(fonts/e-Ukraine-Light.ttf) format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap
  }

  @font-face {
    font-family: Ukraine;
    src: url(fonts/e-Ukraine-Regular.eot);
    src: url(fonts/e-Ukraine-Regular.eot?#iefix) format("embedded-opentype"), url(fonts/e-Ukraine-Regular.woff2) format("woff2"), url(fonts/e-Ukraine-Regular.woff) format("woff"), url(fonts/e-Ukraine-Regular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap
  }

  @font-face {
    font-family: Ukraine;
    src: url(fonts/e-Ukraine-Medium.eot);
    src: url(fonts/e-Ukraine-Medium.eot?#iefix) format("embedded-opentype"), url(fonts/e-Ukraine-Medium.woff2) format("woff2"), url(fonts/e-Ukraine-Medium.woff) format("woff"), url(fonts/e-Ukraine-Medium.ttf) format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap
  }

  @font-face {
    font-family: Ukraine;
    src: url(fonts/e-Ukraine-Bold.eot);
    src: url(fonts/e-Ukraine-Bold.eot?#iefix) format("embedded-opentype"), url(fonts/e-Ukraine-Bold.woff2) format("woff2"), url(fonts/e-Ukraine-Bold.woff) format("woff"), url(fonts/e-Ukraine-Bold.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap
  }


  @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500;600;700&display=swap');

  html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    height: auto;
    font-family: ${fonts.family.Ukraine};
    font-style: normal;
    font-stretch: normal;
    font-weight: ${fonts.weight.normal};
    font-size: ${fonts.size.medium};
    background-color: rgb(${colors.blueblack});
    color: rgb(${colors.white});
    overflow-y:auto;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
      -webkit-text-size-adjust: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  button {
    border-style: none;
    line-height: 1em;
    background-image: none;
    outline: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }

  [tabindex] {
    outline: none;
    width: 100%;
    height: 100%;
  }

  a, p, h1, h2, h3, h4, h5, h6 {
    text-decoration: none;
    margin: 0;
    padding: 0;
    margin: 0.7em 0;
  }

  h1 {
    font-size: ${fonts.size.h1}
  }
  h2 {
    font-size: ${fonts.size.h2}
  }
  h3 {
    font-size: ${fonts.size.h3}
  }
  h4 {
    font-size: ${fonts.size.h4}
  }
  h5 {
    font-size: ${fonts.size.h5}
  }
  h6 {
    font-size: ${fonts.size.h6}
  }

  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    text-decoration: none;
    color: inherit;
    outline: none;
  }

  b,
  strong {
    font-weight: inherit;
    font-weight: bolder;
  }

  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: border-box !important;
  }


  input {
    -webkit-appearance: none;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }
  audio,
  canvas,
  progress,
  video {
    display: inline-block;
  }

  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px;
  }
`;
