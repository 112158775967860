import styled from 'styled-components';
import { useAppState } from '../store';
import { SignInButton, SignOutButton } from '../components/buttons/web3Modal';
import { Account } from '../components/Account';
import { MyTokens } from '../components/MyTokens';
import { TokensChecker } from '../components/TokensChecker';
import { Team } from '../components/Team';
import { ContractLink } from '../components/ContractLink';
import { colors, fonts, responsive } from '../styles';

// Config
import { getNetworksNames } from '../config';

const allowedNetworksNames = getNetworksNames();

const PageWrapper = styled.div`
  margin: 32px auto 0 auto;
  padding: 0 20px 40px 20px;
  width: 100%;
  max-width: 1024px;
  overflow: hidden;

  @media (max-width: ${responsive.md}) {
    margin: 28px auto 0 auto;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 34px;

  @media (max-width: ${responsive.md}) {
    margin-bottom: 28px;
  }
`;

const Logo = styled.div`
  display: block;
  background-image: url(images/logo-grn.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
  margin-right: 8px;

  @media (max-width: ${responsive.md}) {
    width: 28px;
    height: 28px;
  }
`;

const Title = styled.div`
  font-size: ${fonts.size.large};
  font-weight: ${fonts.weight.normal};
  color: rgb(${colors.white});

  @media (max-width: ${responsive.md}) {
    font-size: ${fonts.size.small};
  }
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const AccountBlock = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;

  @media (max-width: ${responsive.md}) {
    align-items: flex-end;
    flex-direction: column;
    white-space: auto;
  }
`;

const SignOutButtonWrapper = styled.div`
  margin-left: 8px;

  @media (max-width: ${responsive.md}) {
    /* margin: 6px 0 -32px 0; */
  }
`;

const WrongNetworkMessage = styled.div`
  padding: 20px;
  border-radius: 20px;
  background-color: rgb(${colors.white});
  font-size: ${fonts.size.medium};
  font-weight: ${fonts.weight.medium};
  color: rgb(${colors.blueblack});

  @media (max-width: ${responsive.md}) {
    padding: 16px;
  }
`;

const Banner =  styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 0 34px 0;
  border-radius: 20px;

  @media (max-width: ${responsive.md}) {
    border-radius: 18px;
    margin: 0 0 28px 0;
  }
`;

const BannerCossack = styled.div`
  display: flex;
  flex-direction: column;
  background-color: black;
  background-image: url(images/bn-bg2.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center right;
  width: auto;
  height: 400px;

  @media (max-width: ${responsive.md}) {
    background-size: cover;
    background-position: center bottom;
  }
`;

const BannerBg = styled.div`
  background-image: url(images/bn-bg1.svg);
  background-repeat: no-repeat;
  background-position: center left;
  background-size: auto 100%;
  width: auto;
  height: 400px;
  padding: 34px;

  @media (max-width: ${responsive.md}) {
    background-position: center top;
    padding: 28px;
  }
`;

const CollectionTitle = styled.div`
  font-size: 58px;
  font-weight: ${fonts.weight.bold};
  line-height: 47px;
  margin-bottom: 40px;

  @media (max-width: ${responsive.md}) {
    font-size: 50px;
    margin-bottom: 20px;
  }
`;

const CollectionInfo = styled.div`
  font-size: 16px;
  font-weight: ${fonts.weight.medium};
  max-width: 370px;
  margin-bottom: 40px;

  @media (max-width: ${responsive.md}) {
    font-size: 16px;
    margin-bottom: 20px;
  }
`;

const OpenSeaLinkBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const OsTitle = styled.div`
  font-size: 20px;
  font-weight: ${fonts.weight.bold};
  margin-right: 12px;
`;

const OsLogo = styled.div`
  background-image: url(images/opensea-logo.png);
  background-repeat: no-repeat;
  background-position: center left;
  background-size: contain;
  width: 157px;
  height: 36px;
`;

export const Main = () => {
  const {
    isEnabled,
    isRightNetwork,
    account,
    token,
    provider,
    isOwnTokens
  } = useAppState();

  return (
    <PageWrapper>
      <Header>
        <Logo />
        <Title>CryptoCossacks</Title>
        <Spacer />
        <AccountBlock>
          <Account account={account} />
          <SignOutButtonWrapper>
            <SignInButton />
            <SignOutButton />
          </SignOutButtonWrapper>
        </AccountBlock>
      </Header>

      {!isOwnTokens &&
        <Banner>
          <BannerCossack>
            <BannerBg>
              <CollectionTitle>
                Crypto<br/>Cossacks
              </CollectionTitle>
              <CollectionInfo>
                A collection that reflects the masculinity, rebelliousness and identity of the Ukrainian people. Each work is a unique blend of history, characters and mystique, adorned with ancient ornaments and inspired by the national Ukrainian style.
              </CollectionInfo>
              <OpenSeaLinkBlock onClick={() => {
                window.open('https://opensea.io/collection/cryptocossacks2022', '_blank');
              }}>
                <OsTitle>
                  Open collection at
                </OsTitle>
                <OsLogo />
              </OpenSeaLinkBlock>
            </BannerBg>
          </BannerCossack>
        </Banner>
      }

      {!isRightNetwork &&
        <WrongNetworkMessage>
          {
            allowedNetworksNames.length > 1
              ? `You are connected to a wrong network. Please switch to one of: ${allowedNetworksNames.join(', ')} networks`
              : `You are connected to a wrong network. Please switch to the ${allowedNetworksNames[0]} network`
          }
        </WrongNetworkMessage>
      }

      {isEnabled &&
        <>
          <MyTokens token={token} account={account} />
          <TokensChecker token={token} account={account} provider={provider} />
        </>
      }

      <Team />

      <ContractLink />

    </PageWrapper>
  );
};
