import type { BigNumber } from 'ethers';
import type { CCContract } from '../../contractLib';
import type { TokenData } from '../../contractLib/types';
import type { Web3ModalProvider } from '../../hooks/useWeb3Modal';
import { useState, useCallback, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { utils as ethersUtils } from 'ethers';
import { TokenMintCard } from '../Layout/TokenCard';
import { BlockTitle } from '../Typography/BlockTitle';
import { MintButton } from '../buttons';
import { ErrorMessage } from '../ErrorMessage';
import { colors, fonts, responsive } from '../../styles';
import { centerEllipsis } from '../../utils/strings';
import { getDefaultNetwork } from '../../config';
// import Logger from '../../utils/logger';

// Initialize logger
// const logger = Logger('MintCard');

// Config
const defaultNetwork = getDefaultNetwork();

export interface MintCardProps {
  token: CCContract;
  provider: Web3ModalProvider;
  tokenId: number;
  tokenPrice: BigNumber;
}

export interface TitleProps {
  large?: boolean;
}

export const CheckerBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  /* padding: 16px; */
  /* background-color: rgb(${colors.white}); */
  margin-top: 40px;
  width: 100%;
  max-width: 570px;
`;

export const TokenCardWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${responsive.md}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  align-self: flex-start;
`;

export const PriceTitle = styled.div`
  font-size: ${fonts.size.medium};
  font-weight: ${fonts.weight.medium};
  color: rgb(${colors.lightgrey});
  margin-right: 4px;
  margin-bottom: 4px;
  align-self: flex-start;

  ${({ large }: TitleProps) => large ? `font-size: ${fonts.size.large};` : ''}
`;

export const PriceValue = styled.div`
  font-size: ${fonts.size.medium};
  font-weight: ${fonts.weight.bold};
  color: rgb(${colors.white});
`;

export const MinButtonWrapper = styled.div`
  @media (max-width: ${responsive.md}) {
    display: flex;
    flex-direction: column;
    align-self: stretch;
  }
`;

export const TxBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const TxTitle = styled.div`
  font-size: ${fonts.size.medium};
  font-weight: ${fonts.weight.medium};
  color: rgb(${colors.lightgrey});
  margin-bottom: 4px;
`;

export const TxHashValue = styled.a`
  font-size: ${fonts.size.medium};
  font-weight: ${fonts.weight.medium};
  color: rgb(${colors.white});
  text-decoration: underline;
`;

export const fetchTokenMeta = async (
  token: CCContract,
  tokenId: number
) => {
  const isAvailable = await token.isTokenMintable(tokenId);

  if (!isAvailable) {
    throw new Error(`Token #${tokenId} is not available for minting`);
  }

  const metadata = await token.getTokenMetadataById(tokenId);
  // const estimation = await token.estimateMinting(tokenId);
  return { metadata };// , estimation
};

export const MintCard = ({ token, provider, tokenId, tokenPrice }: MintCardProps) => {
  const [tokenMetadata, setTokenMetadata] = useState<TokenData | undefined>();
  // const [gasEstimation, setGasEstimation] = useState<BigNumber | undefined>();
  const [metadataLoading, setMetadataLoading] = useState<boolean>(false);
  const [minting, setMinting] = useState<boolean>(false);
  // const [gasPrice, setGasPrice] = useState<BigNumber | undefined>();
  // const [totalPrice, setTotalPrice] = useState<BigNumber | undefined>();
  const [txHash, setTxHash] = useState<string | undefined>();
  const [isMinted, setMinted] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    setMetadataLoading(true);
    setTokenMetadata(undefined);
    setMinted(false);
    setTxHash(undefined);
    setError(undefined);

    fetchTokenMeta(token, tokenId)
      .then(res => {
        // setGasEstimation(res.estimation);
        setTokenMetadata(res.metadata);
      })
      .catch(err => setError(
        (err as Error).message || 'Unknown token metadata loader error'
      ))
      .finally(() => setMetadataLoading(false));
  }, [token, tokenId]);

  // useEffect(() => {
  //   if (
  //     tokenPrice !== undefined &&
  //     gasPrice !== undefined &&
  //     gasEstimation !== undefined
  //   ) {
  //     setTotalPrice(gasEstimation.mul(gasPrice).add(tokenPrice));
  //   }
  // }, [tokenPrice, gasPrice, gasEstimation]);

  const mint = useCallback(async () => {
    if (
      tokenId !== undefined &&
      tokenPrice !== undefined //&&
      // gasPrice !== undefined
    ) {
      try {
        setTxHash(undefined);
        setMinting(true);
        setMinted(false);
        setError(undefined);
        await token.mintToken(
          tokenId,
          {
            value: tokenPrice,
            // gasPrice
          },
          txHash => setTxHash(txHash),
          1
        );
        setMinting(false);
        setMinted(true);
      } catch (err) {
        setError((err as Error).message || 'Unknown token minting error');
        setMinting(false);
        setMinted(false);
      }
    }
  }, [token, tokenId, tokenPrice]);// , gasPrice

  if (tokenId === undefined) {
    return null;
  }

  return (
    <CheckerBlock>
      {metadataLoading &&
        <TokenCardWrapper>
          <PriceTitle>
            Token metadata loading...&nbsp;
            <FontAwesomeIcon icon='sync-alt' spin={true} />
          </PriceTitle>
        </TokenCardWrapper>
      }

      {(!isMinted && !metadataLoading && !!tokenMetadata) &&
        <TokenCardWrapper>
          <TokenMintCard token={tokenMetadata} />
          <BlockTitle center>
            Cossack #{tokenId}
          </BlockTitle>
          {/* <GasPriceSelector
            enabled={!!tokenMetadata && !isMinted}
            paused={minting || isMinted}
            provider={provider}
            onChecked={setGasPrice}
          /> */}
          {!!tokenPrice &&
            <PriceWrapper>
              <PriceTitle>Minting price</PriceTitle>
              <PriceValue>{ethersUtils.formatUnits(tokenPrice, 'ether')} ETH</PriceValue>
            </PriceWrapper>
          }
          {/* {!!totalPrice &&
            <PriceWrapper>
              <PriceTitle>Total token minting costs (price + gas fees)</PriceTitle>
              <PriceValue>{ethersUtils.formatUnits(totalPrice, 'ether')} ETH</PriceValue>
            </PriceWrapper>
          } */}
          <MintButton
            large
            label='Mint NFT'
            disabled={
              minting ||
              tokenId === undefined ||
              tokenPrice === undefined //||
              // gasPrice === undefined
            }
            loading={minting}
            onClick={mint}
          />

        </TokenCardWrapper>
      }
      {isMinted &&
        <TokenCardWrapper>
          <PriceTitle large>
            {`Congratulations! Token #${tokenId} has been successfully minted!`}
          </PriceTitle>
        </TokenCardWrapper>
      }
      {!!txHash &&
        <TokenCardWrapper>
          <TxBlock>
            <TxTitle>
              Transaction hash:&nbsp;
              <TxHashValue
                target="_blank"
                rel="noreferrer"
                href={`${defaultNetwork.blockExplorer}/tx/${txHash}`}
              >
                {centerEllipsis(txHash)}
              </TxHashValue>
            </TxTitle>
          </TxBlock>
        </TokenCardWrapper>
      }
      <ErrorMessage message={error} />
    </CheckerBlock>
  );
};
