import type { CCContract } from '../contractLib';
import type { Web3ModalProvider } from '../hooks/useWeb3Modal';
import type { CheckedToken } from './AvailChip';
import { TakenRange } from '../hooks/useTokenRange';
import { useState, useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTokenRange } from '../hooks/useTokenRange';
import { Container } from './Layout/Container';
import { MintButton } from './buttons';
import { AvailChip } from './AvailChip';
import { MintCard } from './Mint/MintCard';
import { ErrorMessage } from './ErrorMessage';
import { useAppState } from '../store';
import { colors, fonts } from '../styles';

export interface TokensCheckerProps {
  token: CCContract;
  account: string;
  provider: Web3ModalProvider;
}

export const CheckerBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 40px;
  background-color: rgb(${colors.grey});
  margin-right: 8px;
  width: 100%;
  color: rgb(${colors.white});
  border-radius: 20px;
`;

export const ChipsBlock = styled.div`
  margin: 20px 0 0 0;
`;

export const QueryLabel = styled.div`
  font-size: ${fonts.size.medium};
  font-weight: ${fonts.weight.bold};
  color: rgb(${colors.lightgrey});
  margin-bottom: 8px;
`;

export const Query = styled.textarea`
  font-size: ${fonts.size.medium};
  font-weight: ${fonts.weight.medium};
  color: rgb(${colors.white});
  background-color: #363636;
  border-radius: 6px;
  padding: 10px;
`;

export const Alert = styled.div`
  font-size: ${fonts.size.medium};
  font-weight: ${fonts.weight.medium};
  color: rgb(${colors.red});
  background-color: white;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
`;

export const inputValidator = (newValue: string, initialValue: string): string =>
  /^[0-9 ]+$/g.exec(newValue) ? newValue : initialValue;

export const parseQuery = (query: string, range: TakenRange): number[] =>
  query
    .replace(/\s\s+/g, ' ')
    .split(' ')
    .map(t => parseInt(t))
    .filter((t, i) => (t >= range.from && t < range.to && i <= 9));

export const isTokenExists = async (
  token: CCContract,
  tokenId: number
): Promise<boolean> => token.isTokenMintable(tokenId);

export const TokensChecker = ({
  token,
  account,
  provider
}: TokensCheckerProps) => {
  const {
    isMintingPaused
  } = useAppState();
  const [range, tokenPrice, rangeError] = useTokenRange(token);
  const [query, setQuery] = useState<string>('');
  const [checking, setChecking] = useState<boolean>(false);
  const [checkedList, setCheckedList] = useState<CheckedToken[]>([]);
  const [mintTokenId, setMintTokenId] = useState<number | undefined>();
  const [error, setError] = useState<string | undefined>();

  const resetCheckerResults = () => {
    setCheckedList([]);
    setMintTokenId(undefined);
    setChecking(false);
    setError(undefined);
  };

  const isFounder = useMemo(() => range && range.from === 9000, [range]);

  // const defaultCb = useCallback(() => {
  //   if (isMintingPaused && !isFounder) {
  //     setError('Public tokens sale not started yet');
  //     setTimeout(() => {
  //       setError(undefined);
  //     }, 2500);
  //   }
  // }, [isMintingPaused, isFounder]);

  useEffect(() => {
    setQuery('');
    resetCheckerResults();
  }, [account]);

  useEffect(() => {
    setError(rangeError);
  }, [rangeError]);

  const check = useCallback(async () => {
    try {
      resetCheckerResults();
      setChecking(true);

      const parsedQuery = parseQuery(query, range);

      if (parsedQuery.length === 0) {
        throw new Error(
          'None of the requested Ids are within the allowed range.'
        );
      }

      const checked: CheckedToken[] = await Promise.all(
        parsedQuery.map(async tokenId => ({
          tokenId,
          available: await isTokenExists(token, tokenId)
        }))
      );

      setCheckedList(checked);
      setChecking(false);
    } catch (err) {
      setError(
        (err as Error).message || 'Unknown tokens availability checker error'
      );
      setChecking(false);
    }
  }, [token, query, range]);

  if (!token || !account || !provider) {
    return null;
  }

  if (!range) {
    return (
      <Container>
        <CheckerBlock>
          <div>
            Tokens availability checker is loading...&nbsp;
            <FontAwesomeIcon icon='sync-alt' spin={true} />
          </div>
        </CheckerBlock>
      </Container>
    );
  }

  return (
    <Container>
      <CheckerBlock>
        {isMintingPaused && <Alert>At the moment tokens minting is paused. Please back later</Alert>}
        <QueryLabel>
          {`To check tokens availability please enter tokens Ids in the range ${range.from}-${range.to} separated by whitespace (up to 10 Ids)`}
        </QueryLabel>
        <Query
          rows={1}
          value={query}
          onChange={e => setQuery(inputValidator(e.target.value, query))}
        />
        <div>
          <MintButton
            label='Check availability'
            loading={checking}
            disabled={checking}
            onClick={check}
          />
        </div>
        <ErrorMessage message={error} />
        {checkedList.length !== 0 &&
          <ChipsBlock>
            {checkedList.map(
              (t, i) => <AvailChip
                          key={i}
                          token={t}
                          selected={t.tokenId === mintTokenId}
                          isFounder={isFounder}
                          onClick={
                            () => setMintTokenId(t.tokenId)
                            // () => isMintingPaused && !isFounder
                            //   ? defaultCb()
                            //   : setMintTokenId(t.tokenId)
                          }
                        />
            )}
          </ChipsBlock>
        }

        {(!isMintingPaused || (isMintingPaused && isFounder)) &&
          <MintCard
            token={token}
            provider={provider}
            tokenId={mintTokenId}
            tokenPrice={tokenPrice}
          />
        }
      </CheckerBlock>
    </Container>
  );
};
