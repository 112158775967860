import styled from 'styled-components';
import { colors, fonts, responsive } from '../styles';

export interface ImageProps {
  src: string;
}

export const TeamBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;

  @media (max-width: ${responsive.md}) {
    display: block;
    max-width: 230px;
    margin: 0 auto 20px auto;
  }
`;

export const TeamBlockTitle = styled.div`
  font-size: ${fonts.size.medium};
  font-weight: ${fonts.weight.bold};
  color: rgb(${colors.lightgrey});
  margin-top: 80px;
  margin-left: 4px;

  @media (max-width: ${responsive.md}) {
    text-align: center;
    margin-bottom: 12px;
  }
`;

export const Dude = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: ${responsive.md}) {
    display: inline-block;
    margin-bottom: 12px;

    &:nth-child(2) {
      margin-right: 0;
    }
  }
`;

export const DudeTitle = styled.div`
  font-size: ${fonts.size.tiny};
  font-weight: ${fonts.weight.bold};
  color: rgb(${colors.lightgrey});
  margin-top: 5px;

  @media (max-width: ${responsive.md}) {
    text-align: center;
  }
`;

export const DudeImage = styled.img<ImageProps>`
  width: 100px;
  height: 100px;
  border: 1px solid rgb(${colors.grey});
  border-radius: 6px;
`;

export const Team = () => {

  return (
    <>
      <TeamBlockTitle>Team</TeamBlockTitle>
      <TeamBlockWrapper>
        <Dude onClick={() => window.open('https://www.facebook.com/andrey.ermolenko', '_blank')}>
          <DudeImage src='images/t3.png' />
          <DudeTitle>Andrey<br/>Ermolenko</DudeTitle>
        </Dude>
        <Dude onClick={() => window.open('https://www.linkedin.com/in/aleksey-chusov-94a928199', '_blank')}>
          <DudeImage src='images/t4.png' />
          <DudeTitle>Aleks<br/>Chusov<br/>(time4web3)</DudeTitle>
        </Dude>
        <Dude onClick={() => window.open('https://www.linkedin.com/in/kostiantyn-smyrnov/', '_blank')}>
          <DudeImage src='images/t1.png' />
          <DudeTitle>Kostiantyn<br/>Smyrnov</DudeTitle>
        </Dude>
      </TeamBlockWrapper>
    </>
  );
};
