import type { Web3ModalProvider } from './useWeb3Modal';
import { useState, useEffect } from 'react';
import { CCContract } from '../contractLib';

export type UseTokenHook = [
  token: CCContract,
  error: string | null
];

export const useToken = (
  provider: Web3ModalProvider | undefined,
  contractAddress: string
): UseTokenHook => {
  const [token, setToken] = useState<CCContract | undefined>();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!provider) {
      return setToken(undefined);
    }

    try {
      setError(null);
      setToken(new CCContract(contractAddress, provider));
    } catch (err) {

      if (!err.message) {
        setError('Unknown error');
      }

      setError(err.message)
    }

  }, [contractAddress, provider]);

  return [token, error];
};
