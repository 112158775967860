import type { BigNumber } from 'ethers';
import type { CryptoCossacks2022 as CCBaseContract } from '@crypto-cossacks/token/typechain';
import type { TokenData } from '../types';
import * as regexp  from '../utils/regexp';
import { getTokenById } from './getTokenById';

// Returns tokens that owned by the address
export const getOwnedTokens = async (
  contract: CCBaseContract,
  ownerAddress: string
): Promise<TokenData[] | []> => {

  if (!regexp.ethereumAddress.exec(ownerAddress)) {
    throw new Error('getOwnedTokens: Invalid owner address');
  }

  let balance: BigNumber | number  = await contract['balanceOf(address)'](ownerAddress);

  if (balance.eq(0)) {
    return [];
  }

  balance = balance.toNumber();

  const tokens: TokenData[] = [];

  for (let i=0; i<balance; i++) {
    const tokenId = await contract['tokenOfOwnerByIndex(address,uint256)'](
      ownerAddress,
      i
    );
    const token = await getTokenById(contract, tokenId);

    if (!token) {
      throw new Error(`getOwnedTokens: Unable to fetch token with Id: ${tokenId}`);
    }

    tokens.push(token);
  }

  return tokens;
};
