import type { providers } from 'ethers';
import type { CryptoCossacks2022 as CCBaseContract } from '@crypto-cossacks/token/typechain';
import type { MethodOverrides, TxHashCallbackFn } from '../utils/sendHelper';
import type { TokenData } from '../types';
import { getTokenById } from './getTokenById'
import { sendHelper } from '../utils/sendHelper';
import Logger from '../../utils/logger';

// Initialize logger
const logger = Logger('mintToken');

// Mint a token
export const mintToken = async (
  contract: CCBaseContract,
  tokenId: number | string,
  overrides: MethodOverrides,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  transactionHashCb: TxHashCallbackFn,
  confirmations = 1
): Promise<TokenData> => {

  if (await getTokenById(contract, tokenId)) {
    throw new Error(`Token #${tokenId} is already minted`);
  }

  overrides = overrides ? overrides : {};
  logger.debug('Overrides:', overrides);

  const owner = (contract.provider as providers.Web3Provider).getSigner();

  await sendHelper(
    contract,
    'mint(uint256)',
    [
      tokenId
    ],
    owner,
    overrides,
    transactionHashCb,
    confirmations
  );

  return getTokenById(contract, tokenId);
};
