import type { MouseEventHandler } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { colors, fonts, responsive } from '../../styles';

export interface IconButtonWrapperProps {
  size: string;
  color?: string;
  backgroundColor?: string;
}

export interface RefreshButtonProps {
  refresh: MouseEventHandler;
  refreshing: boolean;
}

export interface MintButtonProps {
  label: string;
  loading: boolean;
  disabled: boolean;
  large?: boolean;
  onClick(): void;
}

export interface IconButtonProps {
  size: string;
  icon: string;
  spin: boolean;
  color?: string,
  backgroundColor?: string;
  disabled?: boolean;
  onClick(): void;
}

export interface ButtonLabelProps {
  mr: boolean;
}

export interface GreenButtonProps {
  large?: boolean;
}

export const Button = styled.button`
  padding: 0;
  font-size: ${fonts.size.small};
  font-weight: ${fonts.weight.normal};
  color: rgb(${colors.dark});
  cursor: pointer;
`;

export const RedButton = styled.button`
  display: flex;
  flex-direction: row;
  padding: 12px 24px;
  border-radius: 8px;
  background-color: rgb(${colors.white});
  color: rgb(${colors.blueblack});
  cursor: pointer;
  font-family: ${fonts.family.Ukraine};
  font-size: ${fonts.size.small};
  font-weight: ${fonts.weight.bold};

  @media (max-width: ${responsive.md}) {
    padding: 6px 10px;
    font-size: ${fonts.size.small};
    font-weight: ${fonts.weight.medium};
  }
`;

export const ConnectButton = styled(RedButton)`
  /* margin: 40% auto auto auto; */
`;

export const GreenButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 12px 24px;

  ${({ large }: GreenButtonProps) => large ? 'padding: 24px 48px;' : '' }

  margin-top: 20px;
  background-color: rgb(${colors.white});
  color: rgb(${colors.blueblack});
  border-radius: 8px;
  cursor: pointer;

  @media (max-width: ${responsive.md}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ButtonLabelWrapper = styled.div.attrs(
  (props: ButtonLabelProps) => ({
    mr: props.mr,
  })
)`
  font-size: ${fonts.size.medium};
  font-weight: ${fonts.weight.medium};
  margin-right: ${({ mr }) => mr ? '8px' : '0'};
`;

export const IconButtonWrapper = styled(Button)`
  display: flex;
  align-items: center;
  justify-items: center;
  color: rgb(${colors.red});
  background-color: rgb(${colors.white});
  padding: 5.1px;
  border: 1px solid rgb(${colors.red});
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-left: 8px;
  &:disabled {
    cursor: not-allowed;
  }

  @media (max-width: ${responsive.md}) {
    &:first-child {
      margin-left: 0;
    }
  }
`;

export const ControlButton = ({ icon, size, disabled, onClick }) => (
  <IconButtonWrapper disabled={disabled} onClick={onClick}>
    <div>
      <FontAwesomeIcon icon={icon} size={size} />
    </div>
  </IconButtonWrapper>
);

export const ControlButtonWrapper = styled.div`
  padding: 4px;
  margin-right: 4px;
  color: rgb(${colors.red});
`;

export const RefreshButtonWrapper = styled(RedButton)`
  background-color: none;
`;

export const RefreshButton = ({ refresh, refreshing }: RefreshButtonProps) => (
  <FontAwesomeIcon
    onClick={refresh}
    icon='sync-alt'
    size='2x'
    spin={refreshing}
    color='white'
  />
);

export const MintButton = ({ label, loading, onClick, ...props }: MintButtonProps) => (
  <GreenButton onClick={onClick} {...props}>
    <ButtonLabelWrapper mr={loading}>
      {label}
    </ButtonLabelWrapper>
    {loading &&
      <div>
        <FontAwesomeIcon icon='sync-alt' spin={loading} />
      </div>
    }
  </GreenButton>
);
