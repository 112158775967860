import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppState } from '../store';
import { colors, fonts } from '../styles';

export interface CheckedWrapper {
  selected?: boolean;
}

export interface CheckedToken {
  tokenId: number;
  selected?: boolean;
  paused?: boolean;
  available: boolean;
  founder?: boolean;
}

export interface AvailChipProps {
  token: CheckedToken;
  selected?: boolean;
  isFounder?: boolean;
  onClick?: () => void
}

export const ChipWrapper = styled.div<CheckedWrapper>`
  display: inline-block;
  margin-right: 10px;
  padding: 10px;
  background-color: #363636;
  border-radius: 10px;

  ${
    ({ selected }: CheckedWrapper) => selected
      ? `
        border: solid 1px rgb(${colors.white});
      `
      : ''
  }
`;

export const IconWrapper = styled.div`
  margin-left: 8px;
`;

export const Chip = styled.div<CheckedToken>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${fonts.size.medium};
  font-weight: ${fonts.weight.normal};

  ${
    ({ available, paused, founder }: CheckedToken) => available && (!paused || (paused && founder))
      ? `
        color: rgb(${colors.white});
        text-decoration: underline;
        cursor: pointer;
      `
      : `
        color: rgb(${colors.red});
        text-decoration: none;
      `
  }

  ${
    ({ selected }: CheckedToken) => selected
      ? `
        font-weight: ${fonts.weight.bold};
        text-decoration: none;
        cursor: not-allowed;
      `
      : ''
  }
`;

export const AvailChip = ({
  token,
  selected,
  isFounder,
  onClick = () => {}
}: AvailChipProps) => {
  const {
    isMintingPaused
  } = useAppState();

  return (
    <ChipWrapper selected={selected}>
      <Chip
        {...token}
        selected={selected}
        paused={isMintingPaused}
        founder={isFounder}
        title={
          token.available
            ? isMintingPaused && !isFounder
              ? 'public minting not opened yet'
              : 'available for minting'
            : 'not available'
        }
        onClick={
          onClick
          // isMintingPaused && (token.available || selected)
          //   ? onClick
          //   : () => {}
        }
      >
        <div>
          {token.tokenId}
        </div>
        {(!token.available || (isMintingPaused && !isFounder)) &&
          <IconWrapper>
            <FontAwesomeIcon icon='ban' />
          </IconWrapper>
        }
      </Chip>
    </ChipWrapper>
  );
};
