import styled from 'styled-components';
import { colors, fonts, responsive } from '../../styles';

export interface BlockTitleProps {
  center?: boolean;
}

export const BlockTitle = styled.div`
  font-size: ${fonts.size.medium};
  font-weight: ${fonts.weight.bold};
  color: rgb(${colors.white});
  margin-bottom: 10px;
  text-transform: uppercase;

  ${({ center }: BlockTitleProps) => center ? 'align-self: center;' : ''}

  @media (max-width: ${responsive.md}) {
    font-size: ${fonts.size.small};
  }
`;
